<template>
  <div class="ProducSaleDetail">
    <table-list
      title="商品列表"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="productList"
      :columns="columns(this)"
      :btns="btns(this)"
      :options="{ selection: true }"
      :pager="pager"
      :tabs-list="OptionsList"
      @search="onSearch"
      @selectionChange="handleSelectionChange"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>
<script>
//表格tabs
const OptionsList = [
  {
    prop: 'payStatus',
    activeValue: '',
    children: [
      { value: 'WAIT', label: '待支付' },
      { value: 'SUCCESS', label: '已完成' },
      { value: 'CLOSE', label: '已取消' },
      { value: 'REFUND', label: '已退款' },
    ],
  },
  {
    prop: 'isOnline',
    activeValue: '',
    children: [
      { value: 0, label: '线上' },
      { value: 1, label: '线下' },
    ],
  },
]
// 顶部按钮
const btns = _this => [
  {
    label: '导出',
    method: _this.handleExportTable,
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '订单编号',
    prop: 'orderId',
  },
  {
    label: '购买用户手机',
    prop: 'phone',
  },
  {
    label: '规格',
    type: 'select',
    prop: 'skuCode',
    children: _this.skuData,
  },
  {
    placeholder: '支付渠道',
    type: 'select',
    prop: 'payType',
    children: _this.payTypeTree,
  },
  {
    label: '实付金额',
    type: 'section',
    propLeft: 'beginAmount',
    propRight: 'endAmount',
  },
  {
    label: '创建时间',
    type: 'picker',
    prop: 'createTime',
    pickerDate: _this.pickerDate,
  },
]
// 表格列配置
const columns = () => [
  {
    prop: 'orderIdDetail',
    label: '订单编号',
    minWidth: 150,
  },
  {
    prop: 'payUserName',
    label: '购买用户',
    minWidth: 80,
  },
  {
    prop: 'phone',
    label: '用户手机号',
    minWidth: 110,
  },
  {
    prop: 'skuName',
    label: '规格',
    minWidth: 80,
  },
  {
    prop: 'productSumAmount',
    label: '订单金额',
    minWidth: 76,
    align: 'right',
    sortdata: 1,
    formatter: row => {
      return `￥${row.productSumAmount}`
    },
  },
  {
    prop: 'amount',
    label: '实付金额',
    minWidth: 76,
    align: 'right',
    sortdata: 2,
    formatter: row => {
      return `￥${row.amount}`
    },
  },
  {
    prop: 'orderStatusName',
    label: '订单状态',
    minWidth: 76,
  },
  {
    prop: 'saleType',
    label: '銷售渠道',
    minWidth: 76,
  },
  {
    prop: 'payTypeName',
    label: '支付渠道',
    minWidth: 76,
    formatter: row => {
      return row.payTypeName || '-'
    },
  },
  {
    prop: 'createTimeShow',
    label: '创建时间',
    minWidth: 150,
    sortdata: 3,
  },
  {
    prop: 'payTimeShow',
    label: '支付时间',
    minWidth: 150,
    sortdata: 4,
    formatter: row => {
      return row.payTimeShow || '-'
    },
  },
]
import TableList from '@/components/TableList'
import {
  findProductSaleInfo,
  getAllPayType,
  exportProductSaleInfo,
  getSelectButtonProductSkuInfo,
} from '@/api/center'
import to from 'await-to'
export default {
  name: 'ProducSaleDetail',
  components: {
    TableList,
  },
  data() {
    return {
      pickerDate: {
        onPick: ({ minDate }) => {
          this.pickerMinDate = minDate.getTime()
        },
        disabledDate: time => {
          const year = 365 * 24 * 3600 * 1000
          let oneYearLater = this.pickerMinDate + year
          return time.getTime() > oneYearLater //注意是||不是&&
        },
      },
      productList: [],
      payTypeTree: [],
      skuData: [],
      btns,
      searchForm,
      columns,
      OptionsList,
      pager: {
        total: 0,
        size: 10,
        current: 1,
      },
      loading: false,
      queryParams: {},
      selectProductList: [],
      productId: this.$route.query.productId,
    }
  },
  mounted() {
    this.handleGetProduceSaleList()
    this.handleGetPayTree()
    this.handleGetSku()
  },
  methods: {
    async handleGetSku() {
      const [res, err] = await to(
        getSelectButtonProductSkuInfo({ productId: parseInt(this.productId) }),
      )
      if (err) return this.$message.warning(err.msg)
      this.skuData = res.data.map(item => ({
        label: item.skuName,
        value: item.skuCode,
      }))
    },
    async handleGetPayTree() {
      const [res, err] = await to(getAllPayType({}))
      if (err) return this.$message.warning(err.msg)
      this.payTypeTree = res.data.map(item => ({
        label: item.typeCode,
        value: item.typeName,
      }))
    },
    async handleGetProduceSaleList() {
      if (this.loading) return
      const { current, size } = this.pager
      this.loading = true
      const [res, err] = await to(
        findProductSaleInfo({
          current,
          size,
          ...this.queryParams,
          productId: parseInt(this.productId),
        }),
      )
      this.loading = false
      if (err) return this.$message.warning(err.msg)
      this.productList = res.data.list
      this.pager.total = res.data.total
    },
    // 查询按钮
    onSearch(queryParams) {
      this.pager.current = 1
      this.queryParams = queryParams
      if (queryParams.createTime && queryParams.createTime !== 0) {
        this.queryParams.beginCreateTime = queryParams.createTime[0]
        this.queryParams.endCreateTime = queryParams.createTime[1].split(' ')[0] + ' 23:59:59'
      } else {
        this.queryParams.beginCreateTime = ''
        this.queryParams.endCreateTime = ''
      }
      this.handleGetProduceSaleList()
    },
    async handleExportTable() {
      let ids = []
      this.selectProductList.map(v => ids.push(v.id))
      const { current, size } = this.pager
      const [res, err] = await to(
        exportProductSaleInfo({
          current,
          size,
          productId: parseInt(this.productId),
          ids: ids,
          ...this.queryParams,
        }),
      )
      if (err) return this.$message.warning(err.msg)
      let blob = new Blob([res], {
        type: 'application/vnd.ms-excel',
      })
      let url = window.URL.createObjectURL(blob)
      let a = document.createElement('a')
      a.href = url
      a.download = '商品销售列表.xlsx'
      a.click()
      window.URL.revokeObjectURL(url)
    },
    // 多选
    handleSelectionChange(val) {
      this.selectProductList = val
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.handleGetProduceSaleList()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.handleGetProduceSaleList()
    },
  },
}
</script>
